<template>
  <v-main>
    <HeaderConfigMessage />
    <TableMessageRule />
  </v-main>
</template>
<script>
export default {
  components: {
    HeaderConfigMessage: () => import('./../../../router/views/ecac/components/HeaderConfigMessage.vue'),
    TableMessageRule: () => import('./../../../router/views/ecac/components/TableMessageRule.vue')
  }
};
</script>
<style scoped>
</style>